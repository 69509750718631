import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Button, Dropdown, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import BrijCustomDataTable from "../../components/brijDataTable";
import { BrijDateRange, showErrorToast, showSucessToast } from "../../components/common";
import { selectionRange } from "../../components/constant";
import { commonAPICall } from "../../services/services";
import AngkorRemark from "./AngkorPopup";
import Modal from "react-bootstrap/Modal";
import { mainUrl, previewLiveUrl } from "../../config";
import { FaCog } from "react-icons/fa";
import { toast } from "react-toastify";

const UnPaidApplicationBrowse = () => {
  const pageTitle = useLocation().pathname.split("/")[2];
  const [paymentData, setpaymentData] = useState([]);
  const [excelData, setexcelData] = useState([]);
  const [application, setapplication] = useState();
  const [jsonUpdate, setjsonUpdate] = useState(0);
  const [showDeleteConfirm, setshowDeleteConfirm] = useState(false);
  const [deleteConfirmData, setdeleteConfirmData] = useState({});
  const [showApplicationFee, setShowApplicationFee] = useState(false);
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/ank/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleSubmitApplicationFee = async () => {
    try {
      const result = await commonAPICall(
        "/ank/move_to_paid_payment_insert",
        "post",
        paidData
      );
      showSucessToast("Moved to Paid Successfully");
      setShowApplicationFee(false);
      setjsonUpdate(jsonUpdate - 1);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const [show, setShow] = useState(false);
  const [showType, setshowType] = useState(false);
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    order_id: "",
    nationality: "",
    email: "",
    visitor_id: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    payment_amount: 0,
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
  });

  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    agent_id: null,
  });
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };

  const handeleExcelData = (data) => {
    setexcelData(data);
  };
  const handleAction = (e, id) => {
    if (e.target.value === "View") {
      window.open(
        `${mainUrl}/preview/angkor/${id}`,
        "",
        "width=600,height=300"
      );
    }
    if (e.target.value === "Remarks") {
      setshowType(!showType);
    }
  };

  const openRemarks = (e, temporary_id) => {
    setapplication(temporary_id);
    setshowType(!showType);
  };
  const handleButtonClick = (e, temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/angkor/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };

  const handleShowType = (type) => {
    setshowType(type);
  };
  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/taj/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    paymentApiCall();
  }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };
  const handleSearch = (e) => {
    // let currentQuery = { ...queryParam };
    // currentQuery.search = e.target.value;
    // setQueryParam({ ...queryParam, search: e.target.value });
    // getBrowseData(currentQuery);
  };
  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
  };

  const handleDelete = (row) => {
    setshowDeleteConfirm(true);
    setdeleteConfirmData(row);
  };

  const deletApplicationHandler = async (id) => {
    try {
      const result = await commonAPICall(
        `/ank/ag_application_delete/${id}`,
        "delete",
        ""
      );
      if (result.status === 200) {
        setbodyQuery({ ...bodyQuery, apibody: bodyQuery.apibody + 1 });
        setshowDeleteConfirm(false);
        toast.success("Removed successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowMoveToPaid = (row) => {
    setPaidData(row);
    setShowApplicationFee(true);
  };

  const columns = [
    {
      id: "apply_date",
      name: "Application Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime.replace("Z", "")).format("DD/MM/YYYY")}
            <span>
              {" "}
              {moment(row.datetime.replace("Z", "")).format("hh:mm:ss A")}
            </span>
          </div>
        );
      },
      sortable: true,
      width: "120px",
    },
    {
      id: "Application Id",
      name: "Application Id",
      selector: (row) => row.temporary_id,
      sortable: true,
      minWidth: "145px",
    },
    {
      id: "date",
      name: "Visit Date",
      selector: (row) => {
        return (
          <div className="table__dttime">
            {moment(row.datetime).format("DD/MM/YYYY")}
            <span> {moment(row.datetime).format("hh:mm:ss A")}</span>
          </div>
        );
      },

      sortable: true,
      width: "100px",
    },
    {
      id: "Name",
      name: "Applicant Name",
      selector: (row) => row.Name,
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
      sortable: true,
      omit: false,
      minWidth: "150px",
    },
    {
      id: "email",
      name: "Applicant email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "200px",
      cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
    },
    {
      id: "Mobile",
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "nationality",
      name: "Nationality",
      selector: (row) => (row.nationality ? row.nationality : "N/A"),
      sortable: true,
      minWidth: "100px",
    },
    {
      id: "sales_status",
      name: "Tickets Sales Status",
      selector: (row) => row.sales_status,
      sortable: true,
      omit: false,
      width: "130px",
    },
    {
      id: " Visit Temple  ",
      name: "Temple Name",
      selector: (row) => (
        <div style={{ whiteSpace: "normal" }}>{row.ticket}</div>
      ),
      sortable: true,
      omit: false,
      minWidth: "200px",
    },
    {
      id: "pay_amount",
      name: "Amount Required",
      selector: (row) => (
        <span>
          {row.application_amount}
          {row.currency}
        </span>
      ),
      sortable: true,
      minWidth: "150px",
    },
    {
      id: "gov_ticket_fee",
      name: "Government Tickets Fee",
      selector: (row) => row.govt_fees,
      sortable: true,
      minWidth: "180px",
    },
    {
      id: "visit_days",
      name: "Visit Days",
      selector: (row) => row.pending_days,
      sortable: true,
      minWidth: "100px",
    },
    {
      id: "payment",
      name: "Payment status",
      selector: (row) => row.payment_status,
      sortable: true,
      omit: false,
      minWidth: "120px",
    },
    {
      id: "move_to_paid",
      name: "Move to paid",
      omit: false,
      selector: (row) => {
        return (
          <div className=" customBtn_p">
            <Button
              // onClick={() => handleShow(row.temporary_id, row.pay_amount)}
              onClick={() => handleShowMoveToPaid(row)}
              size="sm"
              variant="danger"
            >
              Move to paid
            </Button>
          </div>
        );
      },
      sortable: true,
      width: "auto",
    },
    {
      id: "email_status",
      name: "Email Status",
      selector: (row) => row.email_status,
      sortable: true,
      minWidth: "130px",
    },
    {
      id: "website_url",
      name: "Site",
      selector: (row) => (
        <a href={row.website_url} target="_blank">
          Visit
        </a>
      ),
      sortable: true,
      omit: false,
      minWidth: "100px",
    },
    {
      id: "action",
      name: "Action",
      omit: false,
      selector: (row) => {
        return (
          <>
            <Dropdown className="action_dropdown">
              <Dropdown.Toggle variant="success" size="sm">
                <FaCog />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => openRemarks(row.temporary_id)}>
                  Remarks
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={(e) =>
                    handleButtonClick(e, row.temporary_id, row.tran_id)
                  }
                >
                  View
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDelete(row)}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
      sortable: true,
      width: "70px",
    },

    // {
    //   id: "datetime",
    //   name: "Payment Record",
    //   selector: (row) => {
    //     return moment(row.datetime).format("DD/MM/YYYY");
    //   },

    //   sortable: true,
    //   minWidth: "190px",
    // },

    // {
    //   id: "sales_manager_status",
    //   name: "Sales Manager Status",
    //   selector: (row) => row.sales_manager_status,
    //   sortable: true,
    //   omit: false,
    //   minWidth: "250px",
    // },

    // {
    //   id: "mobile",
    //   name: "Mobile",
    //   selector: (row) => "+" + row.country_code + "-" + row.mobile,
    //   sortable: true,
    //   minWidth: "200px",
    // },

    // {
    //   id: "sales_status",
    //   name: "Sales Status",
    //   selector: (row) => row.sales_status,
    //   sortable: true,
    //   minWidth: "150px",
    // },

    // {
    //   id: "payment",
    //   name: "Payment",
    //   selector: (row) => row.payment,
    //   sortable: true,
    //   minWidth: "140px",
    // },
  ];

  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
         
            <div className="col-md-2 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.application_id}
                onChange={handleChange}
                type="text"
                name="application_id"
                placeholder="Ticket Id"
              />
            </div>
           
            <div className="col-md-2 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.order_id}
                onChange={handleChange}
                type="text"
                name="order_id"
                placeholder="Order Id"
              />
            </div>
            <div className="col-md-4 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.nationality}
                onChange={handleChange}
                type="text"
                name="nationality"
                placeholder="Nationality"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="ticket"
              >
                <option value={""}>Select Temple</option>
                <option value={"KOHKER TEMPLE"}>KOHKER TEMPLE</option>
                <option value={"ANGKOR WORLD HERITAGE"}>ANGKOR WORLD HERITAGE</option>
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Visit Date"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="sales_manager_status"
              >
                <option value={""}>Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                size="sm"
                onChange={handleChange}
                name="ticket_sales_status"
              >
                <option value={""}>Ticket Sales Status</option>
                <option value={'Paid'}>Paid</option>
                <option value={'Unpaid'}>Unpaid</option>
              </Form.Select>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="m-0" /> */}
      <div className="table-responsive">
        <BrijCustomDataTable
        reloadGrid={jsonUpdate}
          columns={columns}
          url="/ank/main_browse"
          apiBody={apibody}
          paramsBody={bodyQuery}
          // excelData={handeleExcelData}
          assingUrl="/ank/AG_Application_assign_insert"
          type={"unpaid"}
        />

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">
              Application Id : <span>{paidData.id}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex align-items-center gap-3">
              <h5 className="flex-1">Amount:</h5>
              <Form.Control
                size="sm"
                type="number"
                value={paidData.amount}
                onChange={(e) =>
                  setPaidData({ ...paidData, amount: e.target.value })
                }
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button size="sm" variant="success" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <AngkorRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />

      {/* DELETE  */}
      <Modal
        show={showDeleteConfirm}
        onHide={() => setshowDeleteConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Are you sure you want to delete?
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12} className="mb-1">
              <strong>Application Id:</strong>
              <br /> {deleteConfirmData?.temporary_id}
            </Col>
            <Col xs={6} className="mb-1">
              <strong>Name:</strong>
              <br />
              <span className="text-ellipses">{deleteConfirmData?.Name}</span>
            </Col>
            <Col xs={6} className="mb-1">
              <strong>Email:</strong>
              <br />
              <span className="text-ellipses">{deleteConfirmData?.email}</span>
            </Col>
            <Col xs={6} className="mb-1">
              <strong>Payment</strong>
              <br />
              <span className="text-ellipses">
                {deleteConfirmData?.payment}
              </span>
            </Col>
            <Col xs={6} className="mb-1">
              <strong>Payment Status</strong>
              <br />
              <span className="text-ellipses">
                {deleteConfirmData?.payment_status}
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="success"
            onClick={() => deletApplicationHandler(deleteConfirmData?.tran_id)}
          >
            Confirm
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => setshowDeleteConfirm(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* MOVE TO PAID  */}
      <Modal
        show={showApplicationFee}
        onHide={() => setShowApplicationFee(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">
            Application Fee : <span>{paidData.temporary_id}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center gap-3">
            <h5 className="flex-1">Amount:</h5>
            <Form.Control
              size="sm"
              type="number"
              value={paidData.application_amount}
              onChange={(e) =>
                setPaidData({
                  ...paidData,
                  application_amount: e.target.value,
                })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            size="sm"
            onClick={() => setShowApplicationFee(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="success"
            onClick={handleSubmitApplicationFee}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UnPaidApplicationBrowse;
