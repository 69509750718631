import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Select, { ActionMeta, OnChangeValue } from "react-select";
import { commonAPICall } from "../services/services";
import { showErrorToast, showSucessToast } from "../components/common";
function Addusers({ editData, handleIndex }) {
  const [country, setcountry] = useState([]);
  const [selectLink, setselectLink] = useState({});
  const [filter, setfilter] = useState({});
  const [formData, setformData] = useState({
    user_id: 0,
    department: "",
    disable: false,
    email: "",
    emp_code: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    mobile: "",
    password: "",
  });
  const column = [
    {
      label: "INDIA",
      value: "INDIA",
    },
    {
      label: "SRI LANKA",
      value: "SRI LANKA",
    },
    {
      label: "TURKEY",
      value: "TURKEY",
    },
    // {
    //   label: "MYANMAR",
    //   value: "MYANMAR",
    // },
    // {
    //   label: "NEW ZEALAND",
    //   value: "NEW ZEALAND",
    // },
    {
      label: "TAJMAHAL",
      value: "TAJMAHAL",
    },
    {
      label: "CAMBODIA",
      value: "CAMBODIA",
    },
    {
      label: "ANGKOR",
      value: "ANGKOR",
    },
    {
      label: "VIETNAM",
      value: "VIETNAM",
    },
  ];
  useEffect(() => {
    if (editData.valid) {
      commonAPICall(
        "/auth/get_user_right?user_id=" + editData.id,
        "get",
        ""
      ).then((result) => {
        if (result.valid) {
          let value = result.main;
          let tempData = { ...formData };
          for (let key in tempData) {
            if (value.hasOwnProperty(key)) {
              tempData[key] = value[key];
            }
          }
          setcountry(result.country);
          setfilter(result.filter);
          setformData(tempData);
        }
      });
    }
  }, []);
  const userRight = {
    CAMBODIA: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////////////////////////////////ID Same All Portal Code 👆

      { name: "Payment Date", valid: true },
      { name: "Application Date", valid: true },
      { name: "Travel Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Application Type", valid: true },
      { name: "Applicant Name", valid: true },
      { name: "Visa Services", valid: true },
      { name: "Email", valid: true },
      { name: "Visa Status", valid: true },
      { name: "DEO Manager Status", valid: true },
      { name: "DEO Status", valid: true },
      { name: "Application Status", valid: true },
      { name: "Nationality", valid: true },
      { name: "Reference Email", valid: true },
      { name: "Payment", valid: true },
      { name: "Mail Status", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Move to Paid", valid: true },
      { name: "Website", valid: true },
      { name: "Passport Number", valid: true },
      { name: "Sales Manager Status", valid: true },
      { name: "Sales Executive Name", valid: true },
      { name: "Last Active", valid: true },
      { name: "Visa Priority", valid: true },
      { name: "Sales Status", valid: true },
      { name: "Deo", valid: true },
      { name: "Mail Send", valid: true },
      { name: "Action", valid: true },
    ],
    ANGKOR: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal

      { name: "Application Date", valid: true },
      { name: "Date of Payment", valid: true },
      { name: "Visit Date", valid: true },
      { name: "Visit Date", valid: true },
      { name: "Application Id", valid: true },
      { name: "Applicant Name", valid: true },
      { name: "Applicant email", valid: true },
      { name: "Email", valid: true },
      { name: "Mobile", valid: true },
      { name: "Temple Name", valid: true },
      { name: "Visit Temple", valid: true },
      { name: "Visit Days", valid: true },
      { name: "Tickets Sales Status", valid: true },
      { name: "Action", valid: true },
      { name: "Email Status", valid: true },
      { name: "Upload Ticket", valid: true },
      { name: "Tickets", valid: true },
      { name: "Send Ticket", valid: true },
      { name: "Sales User Name", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Move to Paid", valid: true },
      { name: "Payment status", valid: true },
      { name: "Tickets Sales Status", valid: true },
      { name: "Amount Required", valid: true },
      { name: "Government Tickets Fee", valid: true },
      { name: "Action", valid: true },
      { name: "Deo", valid: true },
      { name: "Mail Send", valid: true },
      { name: "Tickets", valid: true },
    ],
    INDIA: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal

      { name: "Payment Record", valid: true },
      { name: "Application Date", valid: true },
      { name: "Travel Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Application Name", valid: true },
      { name: "Application Type", valid: true },
      { name: "Application Status", valid: true },
      { name: "Passport No.", valid: true },
      { name: "Visa Service", valid: true },
      { name: "Email", valid: true },
      { name: "Contact no.", valid: true },
      { name: "Form Status", valid: true },
      { name: "Visa Status", valid: true },
      { name: "DEO", valid: true },
      { name: "Govt ID", valid: true },
      { name: "Sales", valid: true },
      { name: "Sales Manager Status", valid: true },
      { name: "Sales Status", valid: true },
      { name: "DEO Manager Status", valid: true },
      { name: "Visa Days", valid: true },
      { name: "Sales Executive", valid: true },
      { name: "Payment Status", valid: true },
      { name: "Mail Status", valid: true },
      { name: "Last Active", valid: true },
      { name: "Site", valid: true },
      { name: "Move to Paid", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Action", valid: true },
    ],
    "SRI LANKA": [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal

      { name: "Payment Datetime", valid: true },
      { name: "Application Date", valid: true },
      { name: "Travel Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Passport No.", valid: true },
      { name: "Application Type", valid: true },
      { name: "Application Name", valid: true },
      { name: "Visa Service", valid: true },
      { name: "Visa Status", valid: true },
      { name: "Government Id", valid: true },
      { name: "Email", valid: true },
      { name: "Sales Manager Status", valid: true },
      { name: "Sales Status", valid: true },
      { name: "DEO Manager Status", valid: true },
      { name: "Application Status", valid: true },
      { name: "Sales Executive", valid: true },
      { name: "DEO Executive", valid: true },
      { name: "Payment", valid: true },
      { name: "Mail Status", valid: true },
      { name: "Last Active", valid: true },
      { name: "Move to Paid", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Site", valid: true },
      { name: "Action", valid: true },
    ],
    TAJMAHAL: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal

      { name: "Application Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Travel Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Mobile No.", valid: true },
      { name: "Email", valid: true },
      { name: "Sales Manager Status", valid: true },
      { name: "Sales Status", valid: true },
      { name: "DEO Manager Status", valid: true },
      { name: "Application Status", valid: true },
      { name: "Sales Executive", valid: true },
      { name: "Payment Status", valid: true },
      { name: "Move to paid", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Action", valid: true },
      { name: "DEO", valid: true },
    ],
    VIETNAM: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal
      { name: "Payment Datetime", valid: true },
      { name: "Visa Services", valid: true },
      { name: "Visa Status", valid: true },
      { name: "Site", valid: true },
      { name: "Sales Executive", valid: true },
      { name: "DEO Executive", valid: true },
      { name: "Payment", valid: true },
      { name: "Mail Status", valid: true },
      { name: "Last Active", valid: true },

			{ name: "Application Date", valid: true },
			{ name: "Application ID", valid: true },
			{ name: "Application Type", valid: true },
			{ name: "Application Color", valid: true },
			{ name: "Application Amount", valid: true },
			{ name: "Applicant Name", valid: true },
			{ name: "Application Status", valid: true },
			{ name: "Government ID", valid: true },
			{ name: "Travel Date", valid: true },
			{ name: "Application ID", valid: true } ,
			{ name: "Mobile No.", valid: true },
			{ name: "Passport No.", valid: true },
			{ name: "Email", valid: true },
			{name: "Visa Priority", valid: true},
			{name: "Visa Service", valid: true},
			{ name: "Sales Manager Status", valid: true },
			{ name: "Sales Status", valid: true },
			{ name: "Sales Executive", valid: true },
			{ name: "Payment", valid: true },
			{ name: "Last Active", valid: true },
			{ name: "Site", valid: true },
			{ name: "DEO Status", valid: true },
			{ name: "DEO Manager Status", valid: true },
			{ name: "Application Status", valid: true },
			{ name: "Sales Executive", valid: true },
			{ name: "Payment Status", valid: true },
			{ name: "Move to paid", valid: true },
			{ name: "Move to Unpaid", valid: true },
			{ name: "Action", valid: true },
			{ name: "DEO", valid: true },
		],
    TURKEY: [
      { name: "Dashboard", valid: true },
      { name: "Status Update", valid: true },
      { name: "Fee Update", valid: true },
      { name: "Mail Department", valid: true },
      { name: "Setting", valid: true },
      { name: "Paid Browse", valid: true },
      { name: "UnPaid Browse", valid: true },
      { name: "Excel Export", valid: true },
      { name: "Assing Deo", valid: true },
      { name: "Assing Sales", valid: true }, /////Same for all portal

      { name: "Application Date", valid: true },
      { name: "Travel Date", valid: true },
      { name: "Application ID", valid: true },
      { name: "Application Name", valid: true },
      { name: "Passport No.", valid: true },
      { name: "Email", valid: true },
      { name: "Nationality", valid: true },
      { name: "Contact No.", valid: true },
      { name: "Visa Status", valid: true },
      { name: "Sales Manager Status", valid: true },
      { name: "Sales", valid: true },
      { name: "Govt ID", valid: true },
      { name: "Sales Status", valid: true },
      { name: "DEO Manager Status", valid: true },
      { name: "Application Status", valid: true },
      { name: "Sales Executive", valid: true },
      { name: "Payment Status", valid: true },
      { name: "Payment Record", valid: true },
      { name: "Mail Status", valid: true },
      { name: "Last Active", valid: true },
      { name: "Site", valid: true },
      { name: "Move to Paid", valid: true },
      { name: "Move to Unpaid", valid: true },
      { name: "Action", valid: true },
    ],
	};

  const handleClick = (val) => {
    if (filter[val]) {
      setselectLink(val);
    } else {
      setselectLink(val);
      const fil = { ...filter };
      fil[val] = userRight[val];
      setfilter(fil);
    }
  };
  const handleChange = (event, val, index) => {
    const data = [...filter[selectLink]];
    const obj = { ...filter };
    const value = { ...val };
    value.valid = value.valid ? false : true;
    data.splice(index, 1, value);
    obj[selectLink] = data;
    setfilter(obj);
  };
  const handleSubmit = async () => {
    const body = { ...formData };
    body.countrydata = country;
    body.filter = filter;
    try {
      await commonAPICall("/auth/insert", "post", body).then((result) => {
        if (result.valid) {
          showSucessToast("Success");
          window.location.reload();
          // handleIndex(0);
        } else {
          showErrorToast("Please Enter Valid Email or Mobile No");
        }
      });
    } catch (err) {
      alert(err);
    }
  };
  const handleInput = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSelect = (OnChangeValue, ActionMeta) => {
    setcountry(OnChangeValue);
    if (ActionMeta.action == "remove-value") {
      const filterData = { ...filter };
      delete filterData[ActionMeta.removedValue.value];
      setselectLink("");
      setfilter(OnChangeValue.length > 0 ? filterData : []);
    }
  };

  return (
    <>
      <h3> Add Users Details And rights </h3>
      <Container fluid className="mt-3">
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      value={formData.first_name}
                      name="first_name"
                      onChange={handleInput}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="middleName">
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      value={formData.middle_name}
                      name="middle_name"
                      onChange={handleInput}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      value={formData.last_name}
                      name="last_name"
                      onChange={handleInput}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Department</Form.Label>
                  <select
                    className="form-select mb-3"
                    value={formData.department}
                    name="department"
                    onChange={handleInput}
                    aria-label="Department"
                  >
                    <option value={""}>Select Department</option>
                    <option value="Admin">Admin</option>
                    <option value="Sales Manager">Sales Manager</option>
                    <option value="Sales">Sales</option>
                    <option value="Data Entry Manager">
                      Data Entry Manager
                    </option>
                    <option value="Data Entry Operator">
                      Data Entry Operator
                    </option>
                    <option value="CA">CA</option>
                    <option value="HR">HR</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                      value={formData.mobile}
                      name="mobile"
                      onChange={handleInput}
                      type="tel"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      value={formData.email}
                      name="email"
                      onChange={handleInput}
                      type="email"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      value={formData.password}
                      name="password"
                      onChange={handleInput}
                      type="password"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Portal</Form.Label>
                  <Select
                    options={column}
                    isMulti
                    value={country}
                    onChange={handleSelect}
                    closeMenuOnSelect={false}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ul className="nav portal_tabs">
          {country.length > 0
            ? country.map((val, id) => (
                <li
                  className={`nav-link ${
                    val.label === selectLink ? "" : "anchorColor"
                  } bg-warning text-dark me-2 rounded-2 fw-bold `}
                  onClick={() => {
                    handleClick(val.label);
                  }}
                  aria-current="page"
                  href="#"
                  style={{ cursor: "pointer" }}
                >
                  {val.label}
                </li>
              ))
            : ""}
        </ul>

        <Row>
          <Col className="mt-4">
            <Table bordered>
              <thead
                style={{
                  backgroundColor: "#222",
                  color: "#fff",
                  verticalAlign: "middle",
                }}
              >
                <tr>
                  <th>ID</th>
                  <th>Transaction</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {filter[selectLink]
                  ? filter[selectLink].map((val, ind) => (
                      <tr>
                        <td>{ind + 1}</td>
                        <td>{val.name}</td>
                        <td>
                          <Form.Group className="mb-3" controlId="view">
                            <Form.Check
                              type="checkbox"
                              name={val.name}
                              checked={val.valid}
                              onClick={(e) => handleChange(e, val, ind)}
                            />
                          </Form.Group>
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex gap-2 justify-content-end">
            <Button variant="success" onClick={handleSubmit}>
              {editData.valid ? "Update" : "Save"}
            </Button>
            <Button variant="danger" onClick={() => handleIndex(0)}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Addusers;
